/**
 * Each section of the site has its own module. It probably also has
 * submodules, though this boilerplate is too simple to demonstrate it. Within
 * `src/app/home`, however, could exist several additional folders representing
 * additional modules that would then be listed as dependencies of this one.
 * For example, a `note` section could have the submodules `note.create`,
 * `note.delete`, `note.edit`, etc.
 *
 * Regardless, so long as dependencies are managed correctly, the build process
 * will automatically take take of the rest.
 *
 * The dependencies block here is also where component dependencies should be
 * specified, as shown below.
 */
angular.module( 'sq.jobs.public', [
  'sq.jobs.results.component',
  'sq.jobs.results.search.component',
  'sq.jobs.tracker.service',
  /* angular dependencies*/
  /* third party dependencies*/
])

/**
 * Each section or module of the site can also have its own routes. AngularJS
 * will handle ensuring they are all available at run-time, but splitting it
 * this way makes each module more 'self-contained'.
 */

/* Note, we use "id" in the component, not "_id" because Angular doesn't accept _id */
// Use :id? to allow id to be set as query parameter, or as path, ie as ?id=... or /{{id}}
.config(($routeProvider) => {
  $routeProvider
  .when( '/public/:_id?', {
    template: "<sq-job-results id='$resolve.id' type='\"public\"'></sq-job-results>",
    title: 'Public Jobs',
    reloadOnSearch: false,
    secureAsync: false,
    resolve : {
      id : ($route) => $route.current.params._id || $route.current.params.id,
      // provide nice loading bar
      jobs: (jobTracker) => jobTracker.initializeAsync(),
    },
  });
});

/**
 * And of course we define a controller for our routes in separate files
 */
